import React from "react";
import { motion } from "framer-motion";

import { Flex } from "Atoms";
import { useTheme } from "Providers";

export const ThreeDotLoadingSpinner: React.FC = () => {
    const {
        colors: { expressPrimary }
    } = useTheme();

    const style = {
        width: "23px",
        height: "23px",
        backgroundColor: expressPrimary,
        borderRadius: "50%",
        marginLeft: "1rem",
        marginRight: "1rem"
    };
    return (
        <Flex justifyContent="center" alignItems="center" flexBasis="min-content">
            <motion.div
                style={style}
                animate={{ opacity: [0, 1, 0] }}
                transition={{ duration: 1.4, repeat: Infinity, repeatDelay: 0.3 }}
            />
            <motion.div
                style={style}
                animate={{ opacity: [0, 1, 0] }}
                transition={{ duration: 1.4, repeat: Infinity, repeatDelay: 0.3, delay: 0.2 }}
            />
            <motion.div
                style={style}
                animate={{ opacity: [0, 1, 0] }}
                transition={{ duration: 1.4, repeat: Infinity, repeatDelay: 0.3, delay: 0.4 }}
            />
        </Flex>
    );
};
