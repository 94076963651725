import React from "react";
import { MdRestaurant } from "@react-icons/all-files/md/MdRestaurant";
import { FaShoppingBag } from "@react-icons/all-files/fa/FaShoppingBag";

import { ExpressButton } from "../shared/ExpressButton";
import { Languagekey, useLanguage } from "Providers";
import { useTheme } from "ThemeProvider";
import { Box, Flex, BaseBoxProps } from "Atoms";
import { EatingOption } from "Types";

type Props = {
    eatingOptions: EatingOption[];
    setEatingOptions: (eatingSelection: EatingOption) => void;
} & BaseBoxProps;

export const ExpressStartScreenEatingOptions: React.FC<Props> = ({ eatingOptions, setEatingOptions, ...rest }) => {
    const { translate } = useLanguage();
    const { colors } = useTheme();

    return (
        <Flex
            alignItems={"center"}
            justifyContent={eatingOptions.length > 1 ? "space-between" : "center "}
            width="881px"
            {...rest}
        >
            {eatingOptions.map(option => {
                const isEatingHere = option === EatingOption.EAT_HERE;
                return (
                    <ExpressButton
                        key={option}
                        size="xl"
                        themeColor={colors.expressSecondary}
                        color={colors.expressPrimary}
                        rightIcon={isEatingHere ? MdRestaurant : FaShoppingBag}
                        onClick={() => {
                            setEatingOptions(option);
                        }}
                    >
                        <Box color={colors.expressPrimary}>{translate(option as Languagekey)}</Box>
                    </ExpressButton>
                );
            })}
        </Flex>
    );
};
