import { CartProduct, OnlineProduct } from "Types";

export enum EXPRESS_VIEW {
    START_VIEW = "START_VIEW",
    CATEGORY_PRODUCTS_VIEW = "CATEGORY_PRODUCTS_VIEW",
    CHECKOUT = "CHECKOUT",
    BUNDLE_CREATOR_VIEW = "BUNDLE_CREATOR_VIEW"
}

export type ExpressViewComponents = {
    [key in EXPRESS_VIEW]: () => React.JSX.Element;
};

export type ExpresViewDirection = {
    pageView: EXPRESS_VIEW;
    direction: "left" | "right" | "top" | "bottom";
};

export type ExpressViewProps = {
    navigateTo: (expressView: ExpresViewDirection) => void;
    setSelectedOnlineProduct?: React.Dispatch<React.SetStateAction<ExpressBundleCreatorProduct | null>>;
};

export type ExpressBundleCreatorProduct = {
    selectedOnlineBundleProduct: OnlineProduct | null;
    categoryIdAndName: { name: string; id: string };
    cartProductToEdit?: CartProduct | null;
};

export enum ReceiptPrintType {
    FULL = "FULL",
    ORDER_NUMBER = "ORDER_NUMBER"
}

export type ExpressAddonPrice = {
    oldPrice: number;
    newPrice: number;
};
