import React from "react";
import { IoArrowBackCircle } from "@react-icons/all-files/io5/IoArrowBackCircle";

import { OnlineProduct } from "Types";
import { Box, Flex, Text } from "Atoms";
import { ExpressProductInformationCard } from "../product/ExpressProductInformationCard";
import { useLanguage } from "Providers";
import { useTheme } from "Providers/themeProvider/ThemeProvider";

type Props = {
    selectedOnlineProduct?: OnlineProduct;
    navigateBack: () => void;
    categoryIdAndName: { name: string; id: string };
};

export const BundleCreatorProductInfo: React.FC<Props> = React.memo(
    ({ selectedOnlineProduct, navigateBack, categoryIdAndName }) => {
        const {
            colors: { expressLightGrey, expressPrimary }
        } = useTheme();
        const { translateWithArgument } = useLanguage();

        return (
            <Flex
                height={"100%"}
                flexDirection={"column"}
                gridArea={"productInfo"}
                borderRight={"1px solid"}
                alignItems={"flex-start"}
                backgroundColor={"white"}
                zIndex={10}
                borderColor={expressLightGrey}
                px={8}
                pt={12}
            >
                <Flex onClick={navigateBack} alignItems={"center"} flex={"0"}>
                    <Box as={IoArrowBackCircle} w="40px" h="40px" mr={6} color={expressPrimary} />
                    <Text fontSize={"xl"}>{translateWithArgument("backTo", categoryIdAndName.name)}</Text>
                </Flex>

                <ExpressProductInformationCard
                    pt={12}
                    product={selectedOnlineProduct ?? ({} as OnlineProduct)}
                    hasAddons
                    height={"max-content"}
                />
            </Flex>
        );
    }
);
