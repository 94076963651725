import React, { useEffect } from "react";

import { CartProduct, MenuBundleProduct, PosProviderRefProductHashMap } from "Types";
import { Flex } from "Atoms";
import { BundleItemOperation } from "Providers";
import { useTheme } from "ThemeProvider";
import { getBundleProductItems } from "../../../../../../customer/shared/online/components/OnlineProductModal/utils/onlineProductModalUtils";
import { AvailableBundleItem } from "../../../../../../customer/shared/online/components/OnlineProductModal/components";
import { BundleOption } from "./BundleOption";
import { useOrderWindowsStore, usePosStore } from "Stores";
import { ExtendedBundleProductCategory } from "OnlineTypes";
import { getSingleModification } from "TempUtils";
import { isObjectEmpty, buildBundleProductCategories } from "Utils";

type BundleCategoryProps = {
    product: MenuBundleProduct;
    selectedBundleItemId: string;
    isPortrait: boolean;
    setSelectedBundleItemId: (id: string) => void;
};

export const BundleCategory: React.FC<BundleCategoryProps> = ({
    product,
    selectedBundleItemId,
    isPortrait,
    setSelectedBundleItemId
}) => {
    const { colors } = useTheme();
    const { refProducts, hideFromStockIds } = usePosStore();
    const { selectedBundleCartProduct, setSelectedBundleCartProduct, CartManager, productsStock } =
        useOrderWindowsStore();
    const { updateBundleCartProductWithSelectedBundleItems } = CartManager;

    let availableBundleItems: AvailableBundleItem[] = [];
    let categories: ExtendedBundleProductCategory[] = [];

    if (!!product.refBundleProduct?.bundleProductCategories && !isObjectEmpty(selectedBundleCartProduct ?? {})) {
        categories = buildBundleProductCategories(
            product.refBundleProduct?.bundleProductCategories ?? [],
            refProducts as unknown as PosProviderRefProductHashMap
        );

        const bundleProductItems = getBundleProductItems(
            categories[0].refProducts,
            selectedBundleCartProduct as CartProduct,
            categories[0].id,
            productsStock
        );

        availableBundleItems =
            bundleProductItems.length > 1
                ? bundleProductItems.filter(product => !(hideFromStockIds && hideFromStockIds.has(product.id)))
                : [...bundleProductItems];
    }

    useEffect(() => {
        if (selectedBundleItemId && !!availableBundleItems) {
            const selectedBundleItem = availableBundleItems.find(refProduct => refProduct.id === selectedBundleItemId);
            const singleMods = !!selectedBundleItem?.modifications
                ? getSingleModification(selectedBundleItem.modifications)
                : null;

            if (selectedBundleItem) {
                const operation = selectedBundleCartProduct?.oldId
                    ? BundleItemOperation.REPLACE
                    : BundleItemOperation.ADD;
                const productToAdd = refProducts[selectedBundleItem.id as string];
                const updatedCartProduct = updateBundleCartProductWithSelectedBundleItems(
                    selectedBundleCartProduct,
                    productToAdd,
                    categories[0],
                    singleMods,
                    true,
                    null,
                    operation
                );
                setSelectedBundleCartProduct(updatedCartProduct as CartProduct);
            }
        }
    }, [selectedBundleItemId]);

    return (
        <Flex
            h={isPortrait ? "300px" : "260px"}
            w="100%"
            bg={colors.expressSecondary}
            borderRadius={"17px 17px 0 0"}
            alignItems={"center"}
            justifyContent={"center"}
            color={colors.expressPrimary}
            fontSize={"xl"}
            position={"absolute"}
            top={"0"}
            left={"0"}
            zIndex={1}
            overflowY="auto"
        >
            {!!categories.length && (
                <BundleOption
                    availableBundleItems={availableBundleItems}
                    isPortrait={isPortrait}
                    selectedBundleItemId={selectedBundleItemId}
                    setSelectedBundleItemId={setSelectedBundleItemId}
                />
            )}
        </Flex>
    );
};
