import React from "react";

import { BaseBoxProps, Box, Flex, FlexProps, Text } from "Atoms";
import { useTheme } from "ThemeProvider";

type Props = { title: string; wrapperProps?: FlexProps } & BaseBoxProps;

export const ExpressHeader: React.FC<Props> = ({ title, wrapperProps, ...props }) => {
    const {
        colors: { expressPrimary },
        orientation: { isPortrait }
    } = useTheme();

    return (
        <Flex
            alignItems="center"
            justifySelf={"center"}
            width="100%"
            gridArea={"header"}
            py={8}
            maxWidth={isPortrait ? "990px" : "1170px"}
            pr={isPortrait ? 8 : 12}
            {...wrapperProps}
        >
            <Box as="hr" border="1px solid" borderColor={expressPrimary} width="100%" {...props} />
            <Text
                whiteSpace="nowrap"
                mb="0"
                px="16px"
                color="expressTextColor"
                fontSize="2xl"
                fontWeight="600"
                textTransform="capitalize"
                width={"max-content"}
            >
                {title}
            </Text>
            <Box as="hr" border="1px solid" borderColor={expressPrimary} width="100%" {...props} />
        </Flex>
    );
};
