import React from "react";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaMinus } from "@react-icons/all-files/fa/FaMinus";

import { BaseBoxProps, Box, NewGrid, Text } from "Atoms";
import { NewIconButton, NewIconButtonProps } from "Molecules";
import { getTextColor } from "Utils";
import { useTheme } from "Providers";

type Props = {
    quantity: number;
    decrementOrderProduct: (e: any) => void;
    incrementOrderProduct: (e: any) => void;
    limitQuantity?: number;
    isInExpressCart?: boolean;
    isDisabled?: boolean;
} & BaseBoxProps;

const sizes = {
    sm: {
        gridWidth: "112px",
        iconButtonSize: { fontSize: "12px", height: "32px", width: "32px" },
        boxSize: "32px",
        fontSize: "16px",
        columnGap: "6px"
    },
    md: {
        gridWidth: "140px",
        iconButtonSize: { fontSize: "20px", height: "40px", width: "40px" },
        boxSize: "40px",
        fontSize: "20px",
        columnGap: "10px"
    },
    lg: {
        gridWidth: "189px",
        iconButtonSize: { fontSize: "27px", height: "54px", width: "54px" },
        boxSize: "54px",
        fontSize: "27px",
        columnGap: "13px"
    }
};

/** NOTE: unclear if perhaps this should be in Atoms - can't find anything there??? */
export const ExpressQuantitySelector: React.FC<Props> = ({
    quantity,
    decrementOrderProduct,
    incrementOrderProduct,
    limitQuantity = 0,
    isInExpressCart = false,
    size = "md",
    isDisabled = false,
    ...props
}) => {
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();

    const isSmallerSelector = isInExpressCart && !isPortrait;

    const disabledProps = {
        _disabled: {
            cursor: "not-allowed",
            backgroundColor: "expressLightGrey"
        }
    };
    const hoverProps = {
        _hover: {
            backgroundColor: "expressPrimary"
        }
    };
    const arg: NewIconButtonProps = {
        rounded: "4xl",
        type: "button",
        backgroundColor: colors.expressPrimary,
        color: `${getTextColor(colors.expressPrimary)}`,
        ...(isSmallerSelector && { minW: "0" })
    };
    const isIncrementBtnDisabled = limitQuantity != 0 ? limitQuantity <= quantity : false;

    //@ts-ignore
    const selectedSize = sizes[size];

    return (
        <NewGrid
            width={selectedSize.gridWidth}
            templateColumns="repeat(3, 1fr)"
            style={{ columnGap: selectedSize.columnGap }}
            {...props}
        >
            <NewIconButton
                {...arg}
                {...disabledProps}
                {...hoverProps}
                {...selectedSize.iconButtonSize}
                icon={FaMinus}
                isDisabled={isDisabled || quantity <= 0}
                onClick={decrementOrderProduct}
            />
            <Box
                lineHeight={1.8}
                height={selectedSize.boxSize}
                width={selectedSize.boxSize}
                textAlign={"center"}
                border="2px solid"
                borderColor={colors.expressPrimary}
                borderRadius={"5px"}
            >
                <Text
                    fontSize={selectedSize.fontSize}
                    fontWeight={"bold"}
                    fontFamily="poppins"
                    as="span"
                    color={colors.expressPrimary}
                >
                    {quantity}
                </Text>
            </Box>
            <NewIconButton
                {...arg}
                {...disabledProps}
                {...hoverProps}
                {...selectedSize.iconButtonSize}
                icon={FaPlus}
                isDisabled={isDisabled || isIncrementBtnDisabled}
                onClick={incrementOrderProduct}
            />
        </NewGrid>
    );
};
