import React from "react";

import { Box, Flex, NewDivider, Text } from "Atoms";
import { CartProduct } from "Types";
import { ExpressCheckoutCartSummaryCard } from "./ExpressCheckoutCartSummaryCard";
import { useLanguage, useTheme } from "Providers";
import { ExpressButton } from "../shared/ExpressButton";
import { ExpressScrollBar } from "../shared/ExpressScrollBar";
import { formatFinancialNumbers } from "PriceUtils";
import { useQoplaStore } from "Stores";

type Props = {
    cartProducts: CartProduct[];
    navigateStepForward: () => void;
};

export const ExpressCheckoutSummaryCart: React.FC<Props> = ({ cartProducts, navigateStepForward }) => {
    const {
        colors: { expressPrimary, expressMediumGrey }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    const cartTotalSum = cartProducts.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalPrice, 0);
    const totalNetAmount = cartProducts.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalNetPrice, 0);
    const vatAmount = cartTotalSum - totalNetAmount;

    return (
        <Flex width="100%" height="100%" direction={"column"}>
            <Text
                whiteSpace="nowrap"
                mt="8"
                fontSize="3xl"
                fontWeight="600"
                width={"max-content"}
                flexBasis="min-content"
            >
                {translate("yourOrderExpress")}
            </Text>
            <NewDivider opacity={1} color={expressPrimary} borderWidth="2px" width="97%" flexBasis="min-content" />
            <ExpressScrollBar display="flex" flexDirection="column" pr="4" flexBasis="content" mt="4">
                {cartProducts.map((cartProduct, index) => {
                    return (
                        <Box width="100%" my={6} key={cartProduct.id} color="black">
                            <ExpressCheckoutCartSummaryCard cartProduct={cartProduct} />
                            {index + 1 !== cartProducts?.length && <NewDivider color={expressMediumGrey} mt={2} />}
                        </Box>
                    );
                })}
            </ExpressScrollBar>
            <Flex flexBasis="min-content" flexDirection="column">
                <Flex justifyContent="space-between" fontSize="2xl" fontWeight="600" mr="12" mt="8">
                    <Text>{translate("totalSum")}</Text>
                    <Text>{formatFinancialNumbers(cartTotalSum, companyLocale)}</Text>
                </Flex>
                <Flex justifyContent="space-between" fontSize="xl" mr="12">
                    <Text>{translate("vat")}</Text>
                    <Text>{formatFinancialNumbers(vatAmount, companyLocale)}</Text>
                </Flex>
            </Flex>
            <Flex flexBasis="min-content" justifyContent="flex-end" my="6">
                <ExpressButton
                    disabled={cartProducts.length === 0}
                    themeColor={expressPrimary}
                    size={"md"}
                    children={translate("goToExpressPayment")}
                    onClick={navigateStepForward}
                    mx={3}
                />
            </Flex>
        </Flex>
    );
};
