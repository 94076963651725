import React from "react";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";

import { Box, NewDivider, Image, Flex, BaseBoxProps } from "Atoms";
import { useLanguage } from "Providers";
import { useTheme } from "ThemeProvider";
import { CartProduct } from "Types";
import { usePosStore, useQoplaStore } from "Stores";
import { ExpressButton } from "../shared/ExpressButton";
import { formatFinancialNumbers, getModificationPriceText } from "PriceUtils";
import { ExpressScrollBar } from "../shared/ExpressScrollBar";

type Props = {
    cartProduct: CartProduct;
    bundleHasAddons: boolean;
    goToStep: (step: number, bundleCategoryId: string) => void;
    openAddonDrawer: (selectedAddons: any) => void;
} & BaseBoxProps;

export const ExpressBundleCreatorSummary: React.FC<Props> = ({
    cartProduct,
    bundleHasAddons,
    goToStep,
    openAddonDrawer,
    ...props
}) => {
    const {
        colors: { expressPrimary, expressMediumGrey, expressTextColor },
        orientation: { isPortrait }
    } = useTheme();
    const { translate } = useLanguage();
    const { refProducts } = usePosStore();
    const { companyLocale } = useQoplaStore();

    const bundleSelectedItems = cartProduct.orderProduct.selectedBundleProductItems;
    const bundleSelectedAddons = cartProduct.orderProduct.addons;
    const bundleCategories = cartProduct.menuBundleProduct?.refBundleProduct.bundleProductCategories;

    const bundleHasSelectedAddons = !!bundleHasAddons && !!bundleSelectedAddons.length;

    const getImageUrl = (refProductId: string | null) => {
        const refProduct = refProductId ? refProducts[refProductId] : null;
        if (!!refProduct) {
            const { imageUrl, imageResizedUrls } = refProduct;
            return imageResizedUrls?.medium || imageUrl;
        }
        return "";
    };

    return (
        <ExpressScrollBar w={isPortrait ? "1080px" : "1400px"} px={isPortrait ? 16 : 20} {...props}>
            <Box fontSize={isPortrait ? "1.75rem" : "2rem"} mb={4} fontWeight={"600"}>
                {translate("yourChoice")}
            </Box>
            <NewDivider color={expressTextColor} mb={6} />
            <Box
                maxHeight={isPortrait ? "1076px" : "680px"}
                overflowY={"auto"}
                overflowX={"hidden"}
                position="relative"
                style={{ scrollbarWidth: "none" }}
            >
                <Box
                    h={"30px"}
                    w={"100%"}
                    background={"linear-gradient(to bottom, white, transparent)"}
                    position="sticky"
                    top={"-1px"}
                    left={0}
                />
                {bundleSelectedItems?.map((bundleItem, index) => {
                    const imageUrl = getImageUrl(bundleItem.refProductId);
                    const bundleCategory = bundleCategories?.find(
                        bundleCategory => bundleCategory.id === bundleItem.bundleProductCategoryId
                    );
                    const selectedBundleCategoryAndItemText = `${bundleCategory?.name}: ${bundleItem.name}`;
                    let modificationPrice = null;
                    if (bundleItem.modifications) {
                        modificationPrice = getModificationPriceText(bundleItem.modifications, companyLocale);
                    }

                    const isLastSelectedItem = bundleSelectedItems.length - 1 === index;
                    const showDivider = (isLastSelectedItem && bundleHasAddons) || !isLastSelectedItem;
                    const hasBundleItemAddons = !!bundleItem.addons.length;

                    return (
                        <Box key={index}>
                            <Flex  pl={imageUrl ? 0 : "7.75rem"}>
                                {imageUrl && <Image src={imageUrl} h={"100px"} w={"100px"} mr={6} />}
                                <Flex direction={"column"} width={"100%"}>
                                    <Flex alignItems={"center"} justifyContent={"space-between"} h={"auto"}>
                                        <Box fontSize={"xl"} fontWeight={"600"} lineHeight={"40px"}>
                                            {selectedBundleCategoryAndItemText}
                                        </Box>
                                        {!!modificationPrice && (
                                            <Box fontSize={"md"} lineHeight={"40px"}>
                                                {modificationPrice}
                                            </Box>
                                        )}
                                    </Flex>
                                    {hasBundleItemAddons
                                        ? bundleItem.addons.map((addon, index) => {
                                              const addonName =
                                                  addon.quantity === 1
                                                      ? addon.name
                                                      : `${addon.quantity}x  ${addon.name}`;
                                              const addonPrice = addon.price * addon.quantity;
                                              const addonsPriceText =
                                                  addonPrice != 0
                                                      ? `${addonPrice > 0 ? "+" : "-"} ${formatFinancialNumbers(
                                                            addonPrice,
                                                            companyLocale
                                                        )}`
                                                      : "";
                                              return (
                                                  <Flex
                                                      alignItems={"center"}
                                                      justifyContent={"space-between"}
                                                      key={index + addon.name}
                                                  >
                                                      <Box fontSize={"sm"}>{addonName} </Box>
                                                      <Box> {addonsPriceText}</Box>
                                                  </Flex>
                                              );
                                          })
                                        : null}
                                    <ExpressButton
                                        variant="outline"
                                        size={"xs"}
                                        width={"fit-content"}
                                        themeColor={expressPrimary}
                                        rightIcon={MdEdit}
                                        children={translate("change")}
                                        mt={2}
                                        onClick={() => goToStep(index, bundleCategory?.id ?? "")}
                                    />
                                </Flex>
                            </Flex>
                            {showDivider && <NewDivider color={expressMediumGrey} my={6} />}
                        </Box>
                    );
                })}
                {bundleHasAddons && (
                    <>
                        <Box pl={"32"}>
                            <Box fontSize={"xl"} fontWeight={"600"} lineHeight={"40px"}>
                                {translate("addonsAlt")}
                            </Box>
                            {bundleHasSelectedAddons &&
                                bundleSelectedAddons.map((addon, index) => {
                                    const addonName =
                                        addon.quantity === 1 ? addon.name : `${addon.quantity}x  ${addon.name}`;
                                    const addonPrice = addon.price * addon.quantity;
                                    const addonsPriceText =
                                        addonPrice != 0
                                            ? `${addonPrice > 0 ? "+" : "-"} ${formatFinancialNumbers(
                                                  addonPrice,
                                                  companyLocale
                                              )}`
                                            : "";
                                    return (
                                        <Flex
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            key={index + addon.name}
                                        >
                                            <Box fontSize={"md"}>{addonName} </Box>
                                            <Box> {addonsPriceText}</Box>
                                        </Flex>
                                    );
                                })}
                            {!bundleHasSelectedAddons && (
                                <Flex alignItems={"center"} justifyContent={"space-between"}>
                                    <Box fontSize={"md"}>{translate("noneSelected")}</Box>
                                </Flex>
                            )}
                            <ExpressButton
                                variant="outline"
                                size={"xs"}
                                width={"fit-content"}
                                themeColor={expressPrimary}
                                rightIcon={MdEdit}
                                children={translate("change")}
                                mt={4}
                                onClick={() => openAddonDrawer(bundleSelectedAddons)}
                            />
                        </Box>
                    </>
                )}
                <Box
                    h={"36px"}
                    w={"100%"}
                    background={"linear-gradient(to top, white, transparent)"}
                    position="sticky"
                    bottom={"-1px"}
                    left={0}
                />
            </Box>
        </ExpressScrollBar>
    );
};
