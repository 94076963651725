import React from "react";
import styled from "styled-components";

import { Flex, FlexProps } from "Atoms";

// @ts-ignore
const ScrollBarWrapper = styled(Flex)`
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 25px solid white;

    @supports (scrollbar-gutter: auto) {
        scrollbar-gutter: stable;
    }

    @supports (-moz-appearance: none) and (scrollbar-color: auto) {
        scrollbar-color: ${props => props.theme.colors.expressPrimaryLight} ${props => props.theme.colors.expressLightGrey}; 
        scrollbar-width: thin;
    }
`;

export const ExpressScrollBar = React.forwardRef((props: FlexProps, ref) => {
    return <ScrollBarWrapper {...props} ref={ref} />;
});
