import React from "react";

import { ExpressButton } from "../shared/ExpressButton";
import { useLanguage } from "Providers";
import { useTheme } from "ThemeProvider";
import { Flex, Text } from "Atoms";
import { Modal, ModalBody, ModalCloseBtn } from "Molecules";
import { modalNames } from "Constants";
import { getTextColor } from "Utils";
import { EXPRESS_VIEW, ExpresViewDirection } from "../../Utils/types";

type Props = {
    modalContent: {
        navigateTo: (expressView: ExpresViewDirection) => void;
        warningText?: string;
        additionalText?: string;
        navigateBackText?: string;
        continueText?: string;
    };
    closeModal: (modal: string) => void;
};

export const ExpressConfirmationModal: React.FC<Props> = ({ modalContent, closeModal }) => {
    const { translate } = useLanguage();
    const { navigateTo, warningText, navigateBackText, continueText, additionalText } = modalContent;
    const {
        colors: { expressPrimary }
    } = useTheme();
    return (
        <Modal open={true} placement="center" width="640px" minH="390px" borderRadius={"20px"} padding={"24px"}>
            <ModalBody p={"32px"} m="auto" alignContent="center" overflow="hidden">
                <ModalCloseBtn
                    margin={"16px 12px"}
                    backgroundColor={expressPrimary}
                    color={getTextColor(expressPrimary)}
                    borderRadius={"50%"}
                    onClick={() => {
                        closeModal(modalNames.EXPRESS_CONFIRMATION_MODAL);
                    }}
                />
                <Flex
                    width="592px"
                    height="286px"
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <Flex width="358px" height="auto" mb="32px" justifyContent={"center"} direction={"column"}>
                        <Text fontSize="28px" fontWeight="600" fontFamily={"Poppins"} textAlign={"center"}>
                            {!!warningText ? warningText : translate("wantToCancelOrder")}
                        </Text>
                        {!!additionalText && (
                            <Text fontSize={"20px"} textAlign={"center"}>
                                {additionalText}
                            </Text>
                        )}
                    </Flex>
                    <Flex height={"auto"} justifyContent={"center"}>
                        <ExpressButton
                            justifyContent={"center"}
                            themeColor={expressPrimary}
                            size={"md"}
                            variant="outline"
                            width={"244.5px"}
                            mr={"16px"}
                            fontWeight={"normal"}
                            children={!!navigateBackText ? navigateBackText : translate("cancel")}
                            onClick={() => {
                                navigateTo({ pageView: EXPRESS_VIEW.START_VIEW, direction: "left" });
                                closeModal(modalNames.EXPRESS_CONFIRMATION_MODAL);
                            }}
                        />
                        <ExpressButton
                            justifyContent={"center"}
                            themeColor={expressPrimary}
                            size={"md"}
                            width={"244.5px"}
                            variant="solid"
                            children={!!continueText ? continueText : translate("continueShopping")}
                            onClick={() => {
                                closeModal(modalNames.EXPRESS_CONFIRMATION_MODAL);
                            }}
                        />
                    </Flex>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
