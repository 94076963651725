import React, { useEffect, useState } from "react";

import { Box, Flex, Text } from "Atoms";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { modalNames } from "Constants";
import { getTextColor } from "Utils";
import { useLanguage, useTheme } from "Providers";
import { ExpressButton } from "./ExpressButton";

type ModalProps = {
    modalContent: {
        timer: string;
        goToStartScreen: () => void;
    };

    closeModal: (modal: string) => void;
};

const IDLE_TIME = 10; // 10 seconds

export const ExpressIdleModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    const [secondsLeft, setSecondsLeft] = useState(IDLE_TIME);
    const [shouldCloseModal, setShouldCloseModal] = useState(false);

    const {
        colors: { expressPrimary }
    } = useTheme();

    const { translate, translateWithArgument } = useLanguage();

    const { goToStartScreen } = modalContent;

    useEffect(() => {
        if (shouldCloseModal) return;

        const interval = setInterval(() => {
            setSecondsLeft(prevSecondsLeft => {
                if (prevSecondsLeft <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prevSecondsLeft - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [shouldCloseModal]);

    useEffect(() => {
        if (secondsLeft === 0) {
            goToStartScreen();
        }
    }, [secondsLeft]);

    const handleCloseModal = () => {
        setShouldCloseModal(true);
        closeModal(modalNames.EXPRESS_IDLE_MODAL);
    };

    return (
        <Modal
            open={true}
            closeOnDimmerClick
            onClose={handleCloseModal}
            placement="center"
            maxWidth={"43rem"}
            height="390px"
            borderRadius={"1.25rem"}
            padding={6}
        >
            <ModalHeader>
                <ModalCloseBtn
                    onClick={() => handleCloseModal()}
                    bg={expressPrimary}
                    h={"32px"}
                    w={"32px"}
                    borderRadius={"100%"}
                    color={getTextColor(expressPrimary)}
                    top={"24px"}
                    right={"24px"}
                />
            </ModalHeader>
            <ModalBody>
                <Flex direction={"column"} alignItems={"center"}>
                    <Text fontSize={"1.75rem"} fontWeight={"600"} textAlign={"center"} w={"505px"}>
                        {translate("idleMessage")}
                    </Text>
                    <Text fontSize={"2xl"} textAlign={"center"} maxWidth={"360px"} whiteSpace={"nowrap"}>
                        {translateWithArgument("startOverWithArg", secondsLeft)}
                    </Text>
                    <Flex justifyContent={"center"} h={"auto"}>
                        <ExpressButton
                            themeColor={expressPrimary}
                            justifyContent={"center"}
                            size={"md"}
                            variant="outline"
                            mr={"6"}
                            width={"244.5px"}
                            children={translate("startOver")}
                            onClick={() => goToStartScreen()}
                        />
                        <ExpressButton
                            themeColor={expressPrimary}
                            justifyContent={"center"}
                            size={"md"}
                            variant="solid"
                            width={"244.5px"}
                            children={translate("continueShopping")}
                            onClick={() => handleCloseModal()}
                        />
                    </Flex>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
