import React from "react";

import { Box, Flex, FlexProps, Text } from "Atoms";
import { Addon, Modifications, OrderProduct } from "Types";
import { formatFinancialNumbers } from "Utils";
import { useQoplaStore } from "Stores/qoplaStore";
import { Languagekey, useLanguage } from "Providers/languageProvider";
import { getModifications } from "TempUtils";

type Props = {
    orderProduct: OrderProduct;
} & FlexProps;

export const ExpressCartContentCardDetails: React.FC<Props> = ({ orderProduct, ...rest }) => {
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    // quantity is extracted to show the summarized price for the order product
    const { selectedBundleProductItems, modifications, addons, quantity: orderProductQuantity } = orderProduct;
    const addonsList = (addons: Addon[]) =>
        addons?.map(({ name, price, quantity }, idx: number) => {
            const quantityText = quantity > 1 ? ` ${quantity}x ` : " ";
            const countPrice = price * quantity * orderProductQuantity;
            return (
                <Flex
                    height={"max-content"}
                    key={idx}
                    {...rest}
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                >
                    <Text m={0}>{`+${quantityText}${name}`}</Text>
                    {price > 0 && <Text>{`+ ${formatFinancialNumbers(countPrice, companyLocale)}`}</Text>}
                </Flex>
            );
        });

    const modificationsList = (modificationsArray: Modifications) =>
        Object.entries(modificationsArray).map(([key, value]) => {
            // need this since Modification in orderProduct can be Modifications or Modifications[]
            // but in cart we can only have one selected modification
            if (value && !Array.isArray(value)) {
                const { name, price, addonPrice } = value;
                const priceToUse = addonPrice > 0 ? addonPrice : price;
                const priceToUseWithQuantity = priceToUse * orderProductQuantity;
                return (
                    <Flex
                        mt={1}
                        height={"max-content"}
                        key={key}
                        fontSize={"sm"}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text m={0}>{`${translate(key as Languagekey)}: ${name}`}</Text>
                        {priceToUse > 0 && <Text>{formatFinancialNumbers(priceToUseWithQuantity, companyLocale)}</Text>}
                    </Flex>
                );
            }
        });

    return (
        <Box height={"max-content"} mb={4}>
            {!!selectedBundleProductItems &&
                selectedBundleProductItems.map(({ name, modifications, addons }, idx: number) => {
                    const modificationsPrice = !!modifications
                        ? getModifications(modifications).reduce(
                              (price: number, mod: any) =>
                                  price + (mod.mods.addonPrice > 0 ? mod.mods.addonPrice : mod.mods.price),
                              0
                          )
                        : 0;
                    const modPriceWithQuantity = modificationsPrice * orderProductQuantity;
                    return (
                        <Flex key={idx} flexDirection="column" height="max-content" {...rest}>
                            <Flex mt={2} height="max-content" alignItems="center" justifyContent="space-between">
                                <Text m={0}>{name}</Text>
                                {modificationsPrice > 0 && (
                                    <Text m={0}>
                                        {`+ ${formatFinancialNumbers(modPriceWithQuantity, companyLocale)}`}
                                    </Text>
                                )}
                            </Flex>
                            <Box pl={6}>{!!addons && addonsList(addons)}</Box>
                        </Flex>
                    );
                })}
            {!!modifications && modificationsList(modifications)}
            {!!addons && addonsList(addons)}
        </Box>
    );
};
