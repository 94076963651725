import React from "react";
import styled from "styled-components";

import { Box, Flex, FlexProps, Header, NewDivider } from "Atoms";
import { CartProduct } from "Types";
import { ExpressCartContentCard } from "./ExpressCartContentCard";
import { formatFinancialNumbers, getCartTotalPrice } from "Utils";
import { ExpressButton } from "../shared/ExpressButton";
import { AddonsHashMapValue, useLanguage, useTheme } from "Providers";
import { useQoplaStore } from "Stores";

const ExtendedCartWrapper = styled(Flex)`
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const BlurredOverlay = ({ ...props }: FlexProps) => <Flex position="sticky" bottom={"-4px"} zIndex={4} {...props} />;

export const ExpressExtendedCart: React.FC<{
    cartProducts: CartProduct[];
    goToCheckout: () => void;
    handleOpenViewToModify: (cartProduct: CartProduct, productAddons?: AddonsHashMapValue) => void;
}> = ({ cartProducts, goToCheckout, handleOpenViewToModify }) => {
    const {
        colors: { expressPrimary, expressMediumGrey }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();
    const cartTotalPrice = getCartTotalPrice(cartProducts);

    return (
        <Flex width={"100%"} height="100%" direction={"column"}>
            <ExtendedCartWrapper
                direction={"column"}
                width="100%"
                px={4}
                maxHeight="1400px"
                overflowY={"scroll"}
                overflowX="hidden"
            >
                {cartProducts.map(cartProduct => {
                    return (
                        <Box width={"100%"} my={6} key={cartProduct.id}>
                            <ExpressCartContentCard
                                cartProduct={cartProduct}
                                handleOpenViewToModify={handleOpenViewToModify}
                            />
                            <NewDivider color={expressMediumGrey} my={4} />
                        </Box>
                    );
                })}
                <BlurredOverlay
                    width={"100%"}
                    minH={"150px"}
                    maxH={"150px"}
                    background={"linear-gradient(to top, white, transparent)"}
                />
            </ExtendedCartWrapper>
            <NewDivider color={expressMediumGrey} my={4} mx={5} />
            <Flex
                mt={6}
                flexGrow={"1"}
                position="sticky"
                bottom={"10px"}
                marginTop="auto"
                direction="column"
                height={"auto"}
            >
                <Flex mb={10} justifyContent="space-between" alignItems={"baseline"} height={"auto"}>
                    <Header ml={2} as="h2" fontSize={"2xl"}>
                        {translate("totalSum")}
                    </Header>
                    <Header mr={3} as="h2" fontSize={"2xl"}>
                        {formatFinancialNumbers(cartTotalPrice, companyLocale)}
                    </Header>
                </Flex>
                <ExpressButton
                    themeColor={expressPrimary}
                    size={"md"}
                    children={translate("goToCheckout")}
                    onClick={goToCheckout}
                    mx={3}
                    marginLeft="auto"
                />
            </Flex>
        </Flex>
    );
};
