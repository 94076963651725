import React from "react";

import { Box, Flex } from "Atoms";
import { getTextColor } from "Utils";
import { useTheme } from "ThemeProvider";
import { getModificationPriceText } from "PriceUtils";
import { useQoplaStore } from "Stores";
import { AvailableBundleItem } from "../../../../../../customer/shared/online/components/OnlineProductModal/components";

type Props = {
    availableBundleItems: AvailableBundleItem[];
    isPortrait: boolean;
    selectedBundleItemId: string;
    setSelectedBundleItemId: (id: string) => void;
};

export const BundleOption: React.FC<Props> = ({
    availableBundleItems,
    isPortrait,
    selectedBundleItemId,
    setSelectedBundleItemId
}) => {
    const { colors } = useTheme();
    const { companyLocale } = useQoplaStore();

    const optionTextColor = getTextColor(colors.expressSecondary);

    return (
        <Flex width={"100%"} direction={"column"} p={isPortrait ? 8 : 6}>
            {availableBundleItems.map((bundleItem, idx) => {
                const isSelected = selectedBundleItemId === bundleItem.id;
                const priceText = getModificationPriceText(bundleItem.modifications, companyLocale, true);
                return (
                    <Flex key={idx} onClick={() => setSelectedBundleItemId(bundleItem.id)} h="auto" mb={3} w={"100%"}>
                        <Box
                            position={"relative"}
                            height={isPortrait ? "32px" : "28px"}
                            width={isPortrait ? "32px" : "28px"}
                        >
                            <Box
                                border={"2px solid"}
                                borderColor={colors.expressPrimary}
                                height={isPortrait ? "32px" : "28px"}
                                width={isPortrait ? "32px" : "28px"}
                                borderRadius={"full"}
                                position={"absolute"}
                                top={0}
                                left={0}
                            ></Box>
                            {isSelected && (
                                <Box
                                    bg={colors.expressPrimary}
                                    h={isPortrait ? "18px" : "14px"}
                                    w={isPortrait ? "18px" : "14px"}
                                    borderRadius={"full"}
                                    position={"absolute"}
                                    top={"7px"}
                                    left={"7px"}
                                />
                            )}
                        </Box>
                        <Flex alignItems={"center"} justifyContent={"space-between"} w="100%" pl={4}>
                            <Box
                                color={optionTextColor}
                                fontSize={isPortrait ? "md" : "sm"}
                                lineHeight={isPortrait ? "18px" : "16px"}
                                fontWeight={"600"}
                            >
                                {bundleItem.name}
                            </Box>
                            {!!priceText && (
                                <Box color={optionTextColor} fontSize={isPortrait ? "md" : "sm"} whiteSpace={"nowrap"}>
                                    {priceText}
                                </Box>
                            )}
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
};
