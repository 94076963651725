import React from "react";

import { BaseBoxProps, Box, Flex, Image } from "Atoms";
import { MenuProductCategory, OnlineProductCategory } from "Types";
import { getTextColor, getCategoryImage, getOnlineCategoryImage } from "Utils";
import { useTheme } from "ThemeProvider";
import { TruncatedText } from "../shared/TruncatedText";

type Props = {
    category: MenuProductCategory | OnlineProductCategory;
    isDevPage?: boolean;
} & BaseBoxProps;
/** Have to sort out dev page - it look like we use online product instead of menu product category */
export const ExpressCategoryCard: React.FC<Props> = ({ category, isDevPage = false, ...props }) => {
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();

    /** Temp measure need to swap out developer page for online product category  */
    const categoryImage = isDevPage
        ? getCategoryImage(category as MenuProductCategory)
        : getOnlineCategoryImage(category as OnlineProductCategory);

    return (
        <Box
            h={isPortrait ? "390px" : "330px"}
            w={isPortrait ? "300px" : "254px"}
            bg={colors.expressSecondary}
            borderRadius={"1.25rem"}
            position={"relative"}
            {...props}
        >
            {categoryImage && (
                <Image
                    src={categoryImage}
                    h={isPortrait ? "300px" : "254px"}
                    w={isPortrait ? "300px" : "254px"}
                    borderRadius={"1.25rem 1.25rem 0 0"}
                    objectFit={"cover"}
                />
            )}
            <Flex
                h={isPortrait ? "90px" : "76px"}
                w={isPortrait ? "300px" : "254px"}
                bg={colors.expressSecondaryDark}
                borderRadius={"0 0 1.25rem 1.25rem"}
                position="absolute"
                bottom={"0"}
                left={"0"}
                justifyContent={"center"}
                alignItems={"center"}
                px={isPortrait ? 6 : 5}
                color={getTextColor(colors.expressSecondaryDark)}
            >
                <TruncatedText my="auto" fontSize={"lg"} textAlign={"center"} fontWeight="600">
                    {category.name}
                </TruncatedText>
            </Flex>
        </Box>
    );
};
