import React from "react";

import { BaseBoxProps, Flex, FlexProps, Text } from "Atoms";
import { useLanguage, useTheme } from "Providers";
import { ExpressBackButton } from "./ExpressBackButton";
import { ExpressButton } from "./ExpressButton";
import { EXPRESS_VIEW, ExpresViewDirection } from "../../Utils/types";

type Props = {
    title: string;
    expressButtonTitle: string;
    navigateBack: (expressView: ExpresViewDirection) => void;
    navigateTo: (expressView: ExpresViewDirection) => void;
    wrapperProps?: FlexProps;
} & BaseBoxProps;

export const ExpressHeaderWithButtons: React.FC<Props> = ({
    title,
    expressButtonTitle,
    navigateBack,
    navigateTo,
    wrapperProps,
    ...props
}) => {
    const { translate } = useLanguage();
    const {
        orientation: { isPortrait }
    } = useTheme();

    return (
        <Flex
            alignItems="center"
            justifySelf={"center"}
            width="100%"
            gridArea={"header"}
            py={6}
            maxWidth={isPortrait ? "990px" : "1170px"}
            pr={isPortrait ? 8 : 12}
            justifyContent={"space-between"}
            {...wrapperProps}
        >
            <ExpressBackButton
                title={translate("back")}
                onClick={() => navigateBack({ pageView: EXPRESS_VIEW.CATEGORY_PRODUCTS_VIEW, direction: "right" })}
            />
            <Text m={0} fontSize="3xl" fontWeight={"600"}>
                {title}
            </Text>
            <ExpressButton
                size="md"
                children={expressButtonTitle}
                onClick={() => {
                    navigateBack({ pageView: EXPRESS_VIEW.CATEGORY_PRODUCTS_VIEW, direction: "right" });
                    console.log("hit here, To checkout");
                }}
            />
        </Flex>
    );
};
