import React from "react";
import { FaEnvira } from "@react-icons/all-files/fa/FaEnvira";

import { Flex, PseudoBoxProps, Text } from "Atoms";
import { ExpressButton } from "../shared/ExpressButton";
import { useQoplaStore } from "Stores";
import { useLanguage, useTheme } from "Providers";
import { formatFinancialNumbers } from "PriceUtils";
import { CartProduct } from "Types";
import { ReceiptPrintType } from "../../Utils/types";

type Props = {
    cartProducts: CartProduct[];
    selectReceiptTypeAndPay: (receiptPrintType: ReceiptPrintType) => void;
    navigateStepForward: () => void;
} & PseudoBoxProps;

export const ExpressReceipt: React.FC<Props> = ({
    navigateStepForward,
    cartProducts,
    selectReceiptTypeAndPay,
    ...props
}) => {
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();
    const { colors } = useTheme();

    const totalAmount = cartProducts?.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalPrice, 0);
    const totalNetAmount = cartProducts.reduce((acc, cartProduct) => acc + cartProduct?.orderProduct?.totalNetPrice, 0);
    const vatAmount = totalAmount - totalNetAmount;

    return (
        <Flex flexDirection="column" alignItems="center" width="100%">
            <Flex pt="4rem" maxWidth="700px" flexDirection={"column"} alignItems={"center"} {...props}>
                <Text fontSize="4xl" fontWeight="600" mb="24px">
                    {translate("WantFullReceipt")}
                </Text>
                <Text fontSize="2xl" fontWeight="600">
                    {translate("orderNumberAutomatically")}
                </Text>

                <Flex justifyContent={"center"} py="3rem" height="min-content">
                    <ExpressButton
                        w="20rem"
                        themeColor={colors.expressPrimary}
                        variant="outline"
                        size="lg"
                        children={translate("YesPrintReceipt")}
                        mr="4"
                        onClick={() => selectReceiptTypeAndPay(ReceiptPrintType.FULL)}
                    />
                    <ExpressButton
                        w="20rem"
                        themeColor={colors.expressPrimary}
                        size="lg"
                        children={translate("noThanks")}
                        rightIcon={FaEnvira}
                        onClick={() => selectReceiptTypeAndPay(ReceiptPrintType.ORDER_NUMBER)}
                    />
                </Flex>
                <Flex
                    fontSize={"2xl"}
                    flexDirection={"column"}
                    width="100%"
                    justifyContent={"center"}
                    height="min-content"
                >
                    <Flex fontWeight="600" justifyContent={"space-between"}>
                        <Text>{translate("inTotal")}</Text>
                        <Text>{formatFinancialNumbers(Number(totalAmount), companyLocale)}</Text>
                    </Flex>
                    <Flex width="100%" justifyContent={"space-between"}>
                        <Text>{translate("vat")}</Text>
                        <Text>{formatFinancialNumbers(Number(vatAmount), companyLocale)}</Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
