import React, { useEffect } from "react";
import styled from "styled-components";

import { Flex, Header, Box, Text, NewDivider, FlexProps } from "Atoms";
import { AddonsHashMapValue, Languagekey, useLanguage, useTheme } from "Providers";
import { CartProduct } from "Types";
import { ExpressCartContentCard } from "./ExpressCartContentCard";
import { ExpressButton } from "../shared/ExpressButton";
import { useQoplaStore, usePosStore, useOrderWindowsStore } from "Stores";
import { formatFinancialNumbers, getCartTotalPrice } from "Utils";
import { useScrollRef } from "../../hooks/useScrollRef";
import { onlineOrderConstants } from "Constants";

const SideCartWrapper = styled(Flex)`
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const BlurredOverlay = ({ ...props }: FlexProps) => <Flex position="sticky" bottom={"-4px"} zIndex={4} {...props} />;

type Props = {
    cartProducts: CartProduct[];
    numberOfProducts: number;
    goToCheckout: () => void;
    handleOpenViewToModify: (cartProduct: CartProduct, productAddons?: AddonsHashMapValue) => void;
    isEditMode?: boolean;
};

export const ExpressSideCart: React.FC<Props> = ({
    cartProducts,
    numberOfProducts,
    goToCheckout,
    handleOpenViewToModify,
    isEditMode
}) => {
    const {
        colors: { expressPrimary, expressLightGrey }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { translate, translateWithArgument } = useLanguage();
    const { containerRef, resetScroll } = useScrollRef();
    const { addons } = usePosStore();
    const { removeCartProduct } = useOrderWindowsStore();
    const { getActiveOrderWindow } = useOrderWindowsStore();

    const takeAwayTextId = getActiveOrderWindow()?.takeAway
        ? onlineOrderConstants.TAKE_AWAY
        : onlineOrderConstants.EAT_HERE;
    const cartTotalPrice = getCartTotalPrice(cartProducts);

    const productHeader =
        numberOfProducts === 0
            ? translate("emptyCart")
            : numberOfProducts === 1
            ? translateWithArgument("numberOfProduct", numberOfProducts)
            : translateWithArgument("numberOfProducts", numberOfProducts);

    useEffect(() => {
        resetScroll();
    }, [cartProducts.length]);

    const handleRemoveProduct = (cartProduct: CartProduct) => {
        removeCartProduct(cartProduct);
    };
    return (
        <Flex
            alignItems="center"
            justifyContent="flex-start"
            height="100%"
            width="100%"
            backgroundColor="white"
            flexDirection={"column"}
            borderLeft={"1px solid"}
            borderColor={expressLightGrey}
            p={4}
        >
            <Text fontSize="md">{`${productHeader} | ${translate(takeAwayTextId as Languagekey)}`}</Text>
            <Text fontSize={"2xl"} m={0} p={0} mb={4} fontWeight="600">
                {translate("yourOrderExpress")}
            </Text>
            <SideCartWrapper
                direction={"column"}
                width="100%"
                pt={4}
                borderTop={"1px solid"}
                borderColor={expressLightGrey}
                maxHeight="100%"
                overflowY={"scroll"}
                overflowX="hidden"
                ref={containerRef}
            >
                {cartProducts.map(cartProduct => {
                    const productToDisplay =
                        cartProduct.menuProduct?.refProduct || cartProduct.menuBundleProduct?.refBundleProduct;
                    const productAddons = productToDisplay?.id ? addons[productToDisplay.id] : [];
                    return (
                        <Box width={"100%"} my={4} key={cartProduct.id}>
                            <ExpressCartContentCard
                                cartProduct={cartProduct}
                                ml={4}
                                productAddons={productAddons}
                                handleOpenViewToModify={handleOpenViewToModify}
                                handleRemoveProduct={handleRemoveProduct}
                                isEditMode={isEditMode}
                            />
                            <NewDivider color={expressLightGrey} mb={2} />
                        </Box>
                    );
                })}
                <BlurredOverlay
                    width={"100%"}
                    minH={"100px"}
                    maxH={"100px"}
                    background={"linear-gradient(to top, white, transparent)"}
                />
            </SideCartWrapper>
            <Flex
                mt={2}
                flexGrow="1"
                position={"sticky"}
                marginTop="auto"
                direction={"column"}
                height="auto"
                width={"100%"}
                borderTop={"1px solid"}
                borderColor="gray.300"
            >
                <Flex my={4} mx={4} justifyContent="space-between" alignItems={"baseline"} height={"auto"} width="100%">
                    <Text fontSize={"xl"} fontWeight="600">
                        {translate("altSum")}
                    </Text>
                    <Text mr={10} fontSize={"xl"} fontWeight="600">
                        {formatFinancialNumbers(cartTotalPrice, companyLocale)}
                    </Text>
                </Flex>
                <ExpressButton
                    themeColor={expressPrimary}
                    size={"md"}
                    children={translate("goToCheckout")}
                    onClick={goToCheckout}
                    isDisabled={numberOfProducts === 0}
                    mx={3}
                />
            </Flex>
        </Flex>
    );
};
