import React from "react";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";

import { ExpressButton } from "../shared/ExpressButton";
import { Box, Flex, FlexProps, Image, Text } from "Atoms";
import { useLanguage } from "Providers";
import { formatFinancialNumbers } from "PriceUtils";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import { ExpressCartContentCardDetails } from "../cart/ExpressCartContentCardDetails";
import { CartProduct } from "Types";

type Props = { cartProduct: CartProduct; switchOrientation?: boolean } & FlexProps;

//When we plug in this component, we need to finish add the functions for the buttons & the quantity selector, target orderWindows Store.
export const ExpressCheckoutCartSummaryCard: React.FC<Props> = ({
    cartProduct,
    switchOrientation = false,
    ...props
}) => {
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();
    const { removeCartProduct } = useOrderWindowsStore();

    const { menuProduct, menuBundleProduct, orderProduct } = cartProduct;
    const { refProduct } = menuProduct || {};
    const { refBundleProduct } = menuBundleProduct || {};
    const imageUrl = refProduct?.imageUrl || refBundleProduct?.imageUrl;
    const defaultPrice = refProduct?.defaultPrice || refBundleProduct?.defaultPrice || 0;
    const totalDefaultPrice = defaultPrice * orderProduct.quantity;
    const { name, quantity } = orderProduct;

    const isSimpleProduct =
        !orderProduct?.addons?.length &&
        !orderProduct?.selectedBundleProductItems?.length &&
        !Object.keys(orderProduct?.modifications || {})?.length;

    const Img = imageUrl ? (
        <Box w={"112px"} h={"102px"} mr={6}>
            <Image
                objectFit="cover"
                src={imageUrl}
                w={"112px"}
                h={"102px"}
                style={{
                    transition: "width 0.3s ease-in-out"
                }}
            />
        </Box>
    ) : (
        <Box w={"112px"} h={"102px"} mr={6}>
            <Box
                w={"112px"}
                h={"102px"}
                style={{
                    transition: "width 0.3s ease-in-out"
                }}
            />
        </Box>
    );

    return (
        <Flex flexDirection="column" {...props}>
            <Flex flexDirection="row">
                {Img}
                <Flex flexDirection="column" width="100%">
                    <Flex flexDirection="row" width="100%">
                        <Flex
                            mb={2}
                            fontSize="xl"
                            fontWeight="600"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            width="100%"
                        >
                            <Text m={0} width="50%">
                                {quantity + " x " + name}
                            </Text>

                            <Flex alignItems="flex-start">
                                <ExpressButton
                                    onClick={() => {
                                        removeCartProduct(cartProduct);
                                    }}
                                    size="sm"
                                    variant="outline"
                                    children={translate("delete")}
                                    rightIcon={FaTrash}
                                    marginTop={"-3px"}
                                />

                                <Text width={"120px"} m={0} display="flex" justifyContent={"flex-end"}>
                                    {formatFinancialNumbers(totalDefaultPrice, companyLocale)}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <ExpressCartContentCardDetails orderProduct={orderProduct} />
                </Flex>
            </Flex>
        </Flex>
    );
};
