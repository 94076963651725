import React from "react";

import { Flex, Box, BaseBoxProps } from "Atoms";
import { useTheme } from "ThemeProvider";

type Props = {
    startScreenHeader: string;
    startScreenText: string;
} & BaseBoxProps;

export const ExpressStartScreenText: React.FC<Props> = ({ startScreenHeader, startScreenText, ...rest }) => {
    if (!startScreenHeader && !startScreenText) {
        return <></>;
    }
    const { colors } = useTheme();
    const longHeaderText = startScreenHeader.length > 13;
    return (
        <Flex
            h="478px"
            w="881px"
            backgroundColor={colors.expressSecondary}
            color={colors.expressPrimary}
            borderRadius="1.25rem"
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
            {...rest}
        >
            <Box
                fontSize={longHeaderText ? "5rem" : "8rem"}
                letterSpacing={"-1px"}
                fontWeight={"500"}
                mb={"56px"}
                lineHeight={longHeaderText ? "110px" : "90px"}
                textAlign={"center"}
                whiteSpace={"break-spaces"}
            >
                {startScreenHeader}
            </Box>
            <Box fontSize={"4xl"} w="570px" letterSpacing={"-1px"} textAlign={"center"} lineHeight={"150%"}>
                {startScreenText}
            </Box>
        </Flex>
    );
};
