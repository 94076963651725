import { AddonsHashMapValue } from "Providers";
import { OrderProduct, RefBundleProduct } from "Types";

/* Simple budnle product means a product with only 1 bundle product category, no addons, 
    the limit of the category is 1 and max 5 products in the category */
export const checkIsSimpleBundleProduct = (refBundleProduct: RefBundleProduct, hasAddons: boolean) => {
    const productBundleCategories = refBundleProduct.bundleProductCategories;
    return (
        productBundleCategories.length === 1 &&
        !hasAddons &&
        productBundleCategories[0].limit === 1 &&
        productBundleCategories[0].refProductIdList.length <= 5
    );
};

/* Simple product is a refProduct with no addons and no modifications*/
export const checkIsSimpleProduct = (orderProduct: OrderProduct, productAddons: AddonsHashMapValue) => {
    const noModifications = orderProduct.modifications && !Object.keys(orderProduct.modifications)?.length;
    return !productAddons.length && !orderProduct.selectedBundleProductItems?.length && noModifications;
};
