import React from "react";

import { BaseBoxProps, Box, PseudoBox, PseudoBoxProps, Spinner } from "Atoms";
import { useQoplaStore } from "Stores";
import { formatFinancialNumbers, getTextColor } from "Utils";

type ButtonIconProps = {
    icon: React.ComponentType;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    color?: string;
} & BaseBoxProps;

export const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, size = "md", ...rest }) => {
    const iconSize = {
        xl: {
            width: "4rem",
            height: "4rem"
        },
        lg: {
            width: "2rem",
            height: "2rem"
        },
        md: {
            width: "1.25rem",
            height: "1.25rem"
        },
        sm: { width: "1rem", height: "1rem" },
        xs: { width: "0.8rem", height: "0.8rem" }
    };
    //@ts-ignore
    const getIconSize = ({ size = "md" }: ButtonProps) => iconSize[size];
    const styles = { ...getIconSize({ size }) };
    return <Box as={icon} {...styles} {...rest} />;
};

export interface BaseBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    width?: string | number;
    height?: string | number;
    margin?: string;
    htmlType?: string;
    active?: boolean;
}

const baseProps = {
    display: "inline-flex",
    appearance: "none",
    alignItems: "center",
    userSelect: "none",
    position: "relative",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    lineHeight: "1.2",
    outline: "none",
    border: "none",
    transition: "all 0.3s",
    gap: "8px",
    _hover: {
        cursor: "pointer"
    }
};

const disabledProps = {
    _disabled: {
        cursor: "not-allowed",
        backgroundColor: "expressLightGrey",
        color: "expressDarkGrey"
    }
};

const buttonSizes = {
    xl: {
        fontSize: 36,
        px: "72px",
        py: "32px",
        height: 128,
        minWidth: 424.5,
        fontWeight: 400,
        gap: "0px"
    },
    lg: {
        fontSize: 24,
        px: "56px",
        height: 84,
        minWidth: 177,
        gap: "1rem"
    },
    md: {
        fontSize: 20,
        px: "40px",
        height: 62,
        minWidth: 120,
        gap: "0.5rem"
    },
    sm: { height: 37, minWidth: 102, fontSize: 14, px: "32px", gap: "0.5rem" },
    xs: { height: 30, minWidth: 90, fontSize: 12, px: "24px", gap: "0.5rem" }
};

type Button = {
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    isLoading?: boolean;
    isDisabled?: boolean;
    themeColor?: string;
    variant?: "solid" | "outline";
    leftIcon?: React.ComponentType;
    rightIcon?: React.ComponentType;
    price?: string | number;
    loadingText?: string;
};
export type ButtonProps = Button & PseudoBoxProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

//@ts-ignore
const getSize = ({ size = "md" }: ButtonProps) => buttonSizes[size];

const getSolidVariantStyle = ({ themeColor = "expressPrimary" }: ButtonProps) => {
    return {
        bg: themeColor,
        color: getTextColor(themeColor)
    };
};

const getOutlineVariantStyle = ({ themeColor = "expressPrimary" }: ButtonProps) => {
    return {
        border: "1px",
        borderColor: themeColor,
        borderStyle: "solid",
        background: "white",
        color: `expressTextColor`
    };
};

const getVariantStyle = (props: ButtonProps) => {
    if (props.variant === "solid") {
        return getSolidVariantStyle(props);
    } else if (props.variant === "outline") {
        return getOutlineVariantStyle(props);
    }
};

const useButtonStyle = (props: ButtonProps) => {
    return {
        ...baseProps,
        ...getSize(props),
        ...getVariantStyle(props),
        ...disabledProps
    };
};

export const ExpressButton: React.FC<ButtonProps> = React.forwardRef(
    (
        {
            children,
            size = "md",
            variant = "solid",
            themeColor = "expressPrimary",
            leftIcon,
            rightIcon,
            price,
            isLoading,
            loadingText,
            ...props
        },
        ref
    ) => {
        const styles = useButtonStyle({
            size,
            themeColor,
            variant
        });
        const { companyLocale } = useQoplaStore();
        const isString = typeof children === "string";
        //@ts-ignore
        const gap = buttonSizes[size].gap;
        return (
            <PseudoBox
                ref={ref}
                as="button"
                type="button"
                borderRadius="5xl"
                fontWeight="500"
                disabled={props.isDisabled || isLoading}
                justifyContent={(rightIcon || leftIcon) && size == "xl" ? "space-between" : "center"}
                {...styles}
                {...props}
            >
                {leftIcon && !isLoading && (
                    <ButtonIcon
                        icon={leftIcon}
                        marginRight={gap}
                        color={variant == "outline" ? themeColor : props.color}
                    />
                )}
                {isLoading ? (
                    <>
                        <Spinner
                            position={loadingText ? "relative" : "absolute"}
                            size="small"
                            margin={loadingText ? "0 0.5rem 0 0" : "0px"}
                            color="currentColor"
                        />
                        {loadingText && <Box as="span">{loadingText}</Box>}
                    </>
                ) : isString ? (
                    <Box as="span">{children}</Box>
                ) : (
                    children
                )}
                {!price && rightIcon && !isLoading && (
                    <ButtonIcon
                        size={size}
                        icon={rightIcon}
                        marginLeft={gap}
                        color={variant == "outline" ? themeColor : props.color}
                    />
                )}
                {price && !isLoading && (
                    <Box as="span" marginLeft="3.5rem">
                        {formatFinancialNumbers(Number(price), companyLocale)}
                    </Box>
                )}
            </PseudoBox>
        );
    }
);
