import React from "react";
import { TiTick } from "@react-icons/all-files/ti/TiTick";

import { BaseBoxProps, Box, Flex, FlexProps, Image } from "Atoms";
import { useTheme } from "Providers";
import { getTextColor } from "Utils";
import { TruncatedText } from "./TruncatedText";

export type ProgressStep = {
    progressId: string;
    progressStepName: string;
    progressStep: number;
    progressStepText: string;
    progressStepCompleted: boolean;
    isCurrentProgressStep: boolean;
    selectedItemId?: string;
    selectedItemImage?: string;
    isDisabled?: boolean;
};

type Props = {
    selectedProgressItems: ProgressStep[];
    onClickStep?: (stepNumber: number) => void;
    textWrapperProps?: BaseBoxProps;
} & FlexProps;

const ProgressStep = ({ ...props }: FlexProps) => (
    <Flex
        w="72px"
        h="72px"
        borderRadius="full"
        borderWidth="5px"
        borderStyle="solid"
        color="white"
        fontWeight="600"
        justifyContent="center"
        alignItems="center"
        fontSize="2xl"
        {...props}
    />
);

const ProgressLine = ({ ...props }: BaseBoxProps) => <Box flex="1" {...props} />;

const ProgressStepWrapper = ({ ...props }: FlexProps) => (
    <Flex direction="column" alignItems="center" position="relative" height="auto" {...props} />
);

const ProgresStep = ({ step }: { step: ProgressStep }) => {
    if (!!step.selectedItemImage) {
        return <Image src={step.selectedItemImage} objectFit="cover" borderRadius="full" width="100%" height="100%" />;
    }
    return <Box as="span">{step.progressStep}</Box>;
};

const CompletedProgress = ({ step, ...props }: BaseBoxProps & { step: ProgressStep }) => {
    if (!!step.selectedItemImage) {
        return (
            <>
                <Image src={step.selectedItemImage} objectFit="cover" borderRadius="full" width="100%" height="100%" />
                <Box position="absolute" textAlign="center" top={0} {...props}>
                    <Box as={TiTick} />
                </Box>
            </>
        );
    }
    return <Box as={TiTick} {...props} />;
};

export const ExpressProgressIndicator: React.FC<Props> = ({
    selectedProgressItems = [],
    onClickStep,
    textWrapperProps,
    ...rest
}) => {
    const {
        colors: { expressPrimary, expressSuccess },
        orientation: { isPortrait }
    } = useTheme();

    const lastIndex = selectedProgressItems.length - 1;
    const textColour = getTextColor(expressPrimary);

    return (
        <Flex direction={isPortrait ? "row" : "column"} alignItems="center" p={4} width="100%" {...rest}>
            {selectedProgressItems.map((value: ProgressStep, index: number) => {
                const { progressStepText, isCurrentProgressStep, progressStepCompleted, progressStep } = value;

                const showProgressLine = index !== lastIndex;
                const isLastIndex = index === lastIndex;

                const isDisabled = value?.isDisabled;

                return (
                    <React.Fragment key={progressStep}>
                        <ProgressStepWrapper
                            cursor={!isDisabled ? "pointer" : "none"}
                            onClick={() => !isDisabled && !!onClickStep && onClickStep(progressStep)}
                        >
                            <ProgressStep
                                backgroundColor={expressPrimary}
                                borderColor={isCurrentProgressStep ? expressSuccess : expressPrimary}
                                color={textColour}
                                {...(progressStepCompleted && { position: "relative" })}
                            >
                                {progressStepCompleted ? (
                                    <CompletedProgress step={value} color={expressSuccess} fontSize="5xl" />
                                ) : (
                                    <ProgresStep step={value} />
                                )}
                            </ProgressStep>
                            <Box
                                position="absolute"
                                top="75px"
                                textAlign="center"
                                width="200px"
                                zIndex={100}
                                fontSize="sm"
                                fontWeight="600"
                                {...(!isPortrait && {
                                    backgroundColor: "white",
                                    marginTop: "0.5rem",
                                    padding: "0.5rem"
                                })}
                                {...textWrapperProps}
                            >
                                <TruncatedText>{progressStepText}</TruncatedText>
                            </Box>
                        </ProgressStepWrapper>

                        {showProgressLine && (
                            <ProgressLine
                                backgroundColor={expressPrimary}
                                {...(isPortrait ? { height: "3px" } : { minHeight: "75px", width: "3px" })}
                            />
                        )}
                        {isLastIndex && !isPortrait && (
                            <ProgressLine
                                backgroundColor={expressPrimary}
                                minHeight="7px"
                                maxHeight="7px"
                                width="3px"
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </Flex>
    );
};
