import React, { useState, useEffect } from "react";

import { RefProduct, MenuBundleProduct, MenuProduct, OnlineProduct, CartProduct } from "Types";
import { Box } from "Atoms";
import { AddonsHashMapValue, useDrawer, useLanguage } from "Providers";
import { useTheme } from "ThemeProvider";
import { useOrderWindowsStore, usePosStore, useQoplaStore } from "Stores";
import {
    getPriceStringForMenuProductWithLocale,
    getPriceStringForMenuBundleProductWithLocale,
    getPriceStringForProductWithLocale,
    getModificationPriceText
} from "PriceUtils";
import { hasBundleModificationPrice } from "../../../../../admin/components/poses/pos/utils";
import {
    ProductImage,
    ProductCardFooter,
    ProductCardFooterSelected,
    BundleCategory,
    ProductCardFooterSimpleBundle,
    ProductCardFooterBundleItem,
    ProductCardAnimation
} from "./components";
import { ExpressProductInformationBanner } from "./ExpressProductInformationBanner";
import { tempBundleProductOrderProduct } from "../../../poses/shared/utils/cartController";
import { checkIsSimpleBundleProduct } from "../../Utils/expressProductUtils";

export type ProductToAdd = RefProduct | MenuProduct | MenuBundleProduct;

type Props = {
    product: OnlineProduct;
    selectedProductId: string | null;
    setSelectedProductId: (id: string | null) => void;
    addProduct?: (
        product: OnlineProduct,
        addToCartAnimation?: () => void,
        isSimpleBundle?: boolean,
        isEditingSimpleBundle?: boolean
    ) => void;
    openProductPage?: (productAddons?: AddonsHashMapValue, isBundleProduct?: boolean) => void;
    simpleBundleIdToEdit?: string | null;
    hasAddons?: boolean;
    isOutOfStock?: boolean;
    bundleItemProps?: {
        isBundleItem: boolean;
        handleSelectBundleItem?: (product: OnlineProduct) => void;
    };
};

export const ExpressProductCard: React.FC<Props> = ({
    product,
    selectedProductId,
    setSelectedProductId,
    addProduct,
    openProductPage,
    isOutOfStock = false,
    simpleBundleIdToEdit,
    bundleItemProps
}) => {
    const { selectedShop } = useQoplaStore();
    const [wasAdded, setWasAdded] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);
    const [isBundleCategoryOpen, setIsBundleCategoryOpen] = useState(false);
    const [selectedBundleItemId, setSelectedBundleItemId] = useState<string>("");

    const { translate } = useLanguage();
    const {
        colors,
        orientation: { isPortrait }
    } = useTheme();
    const { companyLocale } = useQoplaStore();
    const { refProducts, addons } = usePosStore();
    const { onOpenDrawer } = useDrawer();
    const { selectedBundleCartProduct, setSelectedBundleCartProduct } = useOrderWindowsStore();

    const isSelected = selectedProductId === product.id;
    const isEditingSimpleBundle = simpleBundleIdToEdit === product.id;
    const { isBundleItem, handleSelectBundleItem } = bundleItemProps || {};

    useEffect(() => {
        if (isOutOfStock && isSelected) {
            setSelectedProductId(null);
        }
    }, [isOutOfStock]);

    useEffect(() => {
        const isNewProductSelected =
            !!selectedProductId && !!selectedBundleCartProduct?.menuBundleProduct?.id
                ? selectedProductId !== selectedBundleCartProduct?.menuBundleProduct?.id
                : false;

        if (!!simpleBundleIdToEdit && !isNewProductSelected) {
            setIsBundleCategoryOpen(true);
            if (selectedBundleCartProduct?.orderProduct?.selectedBundleProductItems?.length) {
                setSelectedBundleItemId(
                    selectedBundleCartProduct.orderProduct.selectedBundleProductItems[0].refProductId ?? ""
                );
            }
        }

        if (simpleBundleIdToEdit !== product.id) {
            setIsBundleCategoryOpen(false);
            setSelectedBundleItemId("");
        }

        if (isNewProductSelected && !isBundleItem) {
            setSelectedBundleCartProduct(null);
            setIsBundleCategoryOpen(false);
            setSelectedBundleItemId("");
        }
    }, [selectedProductId, simpleBundleIdToEdit]);

    const isBundleProduct = "refBundleProduct" in product && !!product.refBundleProduct;

    const addToCartAnimation = () => {
        setStartAnimation(true);
        setTimeout(() => {
            setStartAnimation(false);
        }, 1000);
    };

    const showMessageAddedProduct = () => {
        setWasAdded(true);
        setTimeout(() => {
            setWasAdded(false);
        }, 600);
    };

    const handleAddSimpleBundleProduct = () => {
        if (selectedBundleItemId && isBundleCategoryOpen) {
            setIsBundleCategoryOpen(false);
            setSelectedBundleItemId("");
            showMessageAddedProduct();
            addProduct && addProduct(product, addToCartAnimation, true, isEditingSimpleBundle);
        } else {
            const tempBundleProduct = tempBundleProductOrderProduct(
                product.menuBundleProduct as MenuBundleProduct,
                selectedShop?.id!,
                product.id,
                1,
                null,
                null
            );
            setIsBundleCategoryOpen(true);
            setSelectedBundleCartProduct(tempBundleProduct as CartProduct);
        }
    };

    const handleBundleItem = () => {
        if (!!handleSelectBundleItem) {
            handleSelectBundleItem(product);
            setSelectedProductId(null);
        }
    };

    let productToDisplay = null;
    let productPriceWithCurrency = "";

    // To save space in the product card, we skip the decimals in the price, because those are uncommon
    // For other countries it might be needed
    const skipDecimalsInProductCard = true;

    if ("menuProduct" in product && product.menuProduct) {
        productToDisplay = product.refProduct as RefProduct;
        productPriceWithCurrency = getPriceStringForMenuProductWithLocale(
            product.menuProduct,
            companyLocale,
            skipDecimalsInProductCard
        );
    } else if ("menuBundleProduct" in product && product.menuBundleProduct) {
        productToDisplay = product.refBundleProduct;
        const hasModPrice = hasBundleModificationPrice(product, refProducts);
        const extraText = hasModPrice ? `${translate("from")} ` : "";
        productPriceWithCurrency = getPriceStringForMenuBundleProductWithLocale(
            product.menuBundleProduct!,
            companyLocale,
            skipDecimalsInProductCard,
            extraText
        );
    } else if (isBundleItem) {
        productToDisplay = product.refProduct;
        productPriceWithCurrency =
            getModificationPriceText(product.modifications as any, companyLocale, skipDecimalsInProductCard) ?? "";
    } else {
        productToDisplay = product.refProduct || product.refBundleProduct;
        productPriceWithCurrency = getPriceStringForProductWithLocale(
            productToDisplay!,
            companyLocale,
            skipDecimalsInProductCard
        );
    }

    const productAddons = productToDisplay && productToDisplay.id ? addons[productToDisplay.id] || [] : [];
    const hasAddons = !!productAddons.length;

    let isSimpleBundle = false;
    if (isBundleProduct && product.refBundleProduct) {
        isSimpleBundle = checkIsSimpleBundleProduct(product.refBundleProduct, hasAddons);
    }

    if (!productToDisplay) {
        return null;
    }

    const handleAddProductClick = () => {
        if (!hasAddons && !isBundleProduct) {
            !!addProduct && addProduct(product, addToCartAnimation);
            showMessageAddedProduct();
        } else {
            !!openProductPage && openProductPage(productAddons, isBundleProduct);
        }
    };

    const { imageUrl, imageResizedUrls, name } = productToDisplay;
    const productImage = imageResizedUrls?.medium || imageUrl;

    const isSelectedProduct = isSelected || wasAdded || isBundleCategoryOpen;

    const getBorderColor = () => {
        if (isOutOfStock) {
            return colors.expressLightGrey;
        }
        if (isSelectedProduct) {
            return colors.expressPrimary;
        }
        return colors.expressSecondaryDark;
    };

    const footerProps = {
        productName: productToDisplay.name,
        productPrice: productPriceWithCurrency,
        wasAdded: wasAdded,
        isPortrait: isPortrait
    };

    const canToggleSelected = !isOutOfStock && !isBundleCategoryOpen && !(isSimpleBundle && isSelected);

    return (
        <Box
            h={isPortrait ? "380px" : "330px"}
            w={isPortrait ? "300px" : "260px"}
            bg={isOutOfStock ? "expressLightGrey" : colors.expressSecondary}
            borderRadius={"1.25rem"}
            position={"relative"}
            border={`3px solid`}
            borderColor={getBorderColor()}
            cursor={"pointer"}
            onClick={() => canToggleSelected && setSelectedProductId(isSelected ? null : product.id)}
        >
            <ProductCardAnimation imageUrl={productImage} startAnimation={startAnimation} />

            <ProductImage
                productImage={productImage}
                isOutOfStock={isOutOfStock}
                productName={name}
                isPortrait={isPortrait}
            />
            {isBundleCategoryOpen && (
                <BundleCategory
                    product={product as MenuBundleProduct}
                    selectedBundleItemId={selectedBundleItemId}
                    isPortrait={isPortrait}
                    setSelectedBundleItemId={setSelectedBundleItemId}
                />
            )}

            {isOutOfStock && (
                <Box
                    position={"absolute"}
                    top={isPortrait ? "121px" : "110px"}
                    left={isPortrait ? "21px" : "8px"}
                    minH="52px"
                    w={isPortrait ? "262px" : "235px"}
                    color={"black"}
                    fontWeight={"600"}
                    fontSize={isPortrait ? "2xl" : "xl"}
                    bg="white"
                    borderRadius={"2.5rem"}
                    zIndex={1}
                    py={2}
                    px={8}
                    textAlign={"center"}
                >
                    {translate("tempOutOfStock")}
                </Box>
            )}
            {isSelectedProduct ? (
                <>
                    {!(wasAdded || isBundleCategoryOpen) && (
                        <ExpressProductInformationBanner
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.stopPropagation();
                                onOpenDrawer("expressProductInformationDrawer", { product: product });
                            }}
                            border={"3px solid"}
                            borderColor={colors.expressPrimary}
                            position={"absolute"}
                            left={"-3px"}
                            top={isPortrait ? "164px" : "142px"}
                            bg={"white"}
                            height={isPortrait ? "64px" : "55px"}
                            width={isPortrait ? "72px" : "62px"}
                        />
                    )}
                    {isSimpleBundle ? (
                        <ProductCardFooterSimpleBundle
                            isBundleCategoryOpen={isBundleCategoryOpen}
                            handleAddProductClick={() => !wasAdded && handleAddSimpleBundleProduct()}
                            isEditingSimpleBundle={isEditingSimpleBundle}
                            {...footerProps}
                        />
                    ) : null}

                    {isBundleItem ? (
                        <ProductCardFooterBundleItem handleAddProductClick={handleBundleItem} {...footerProps} />
                    ) : null}

                    {!(isSimpleBundle || isBundleItem) && (
                        <ProductCardFooterSelected
                            handleAddProductClick={() => !wasAdded && handleAddProductClick()}
                            {...footerProps}
                        />
                    )}
                </>
            ) : (
                <ProductCardFooter
                    productName={name}
                    productPrice={productPriceWithCurrency}
                    isOutOfStock={isOutOfStock}
                    isPortrait={isPortrait}
                />
            )}
        </Box>
    );
};
