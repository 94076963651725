import React, { useEffect, useRef, useState } from "react";
import { IoMdHome } from "@react-icons/all-files/io/IoMdHome";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import styled from "styled-components";

import { Box, Text, Image, Flex, FlexProps } from "Atoms";
import { useLanguage } from "Providers";
import { useTheme } from "ThemeProvider";
import { getTextColor } from "Utils";
import { useScrollRef } from "../../hooks/useScrollRef";

export type NavigationCategory = {
    categoryId: string;
    categoryName: string;
    isSelected: boolean;
    categoryImageUrl?: string | null;
    isExternalClick?: boolean;
};

enum SCROLL_DIRECTION {
    UP = "UP",
    DOWN = "DOWN",
    LEFT = "LEFT",
    RIGHT = "RIGHT"
}

type Props = {
    categoryNavigationItems: NavigationCategory[];
    onSelectCategory: (selectedCategory?: NavigationCategory) => void;
};

const NavigationWrapper = styled(Flex)`
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const NavigationItem = React.forwardRef((props: FlexProps, ref) => (
    <Flex
        w="120px"
        h="120px"
        minH="120px"
        minW="120px"
        justifyContent="center"
        alignItems="center"
        {...props}
        ref={ref}
    />
));

const Home = ({ ...props }: FlexProps) => (
    <>
        <Flex direction="column" justifyContent="center" alignItems="center" {...props}>
            <Box as={IoMdHome} w="40px" h="40px" />
            <Text m={0} p={0} textAlign="center" fontSize="sm">
                {props.children}
            </Text>
        </Flex>
    </>
);

const BlurredOverlay = ({ ...props }: FlexProps) => <Flex position="sticky" zIndex={4} {...props} />;

const Category = ({
    imageUrl,
    isPortrait,
    ...props
}: FlexProps & { imageUrl?: string | null; isPortrait: boolean }) => (
    <>
        <Flex direction="column" justifyContent="center" alignItems="center" {...props}>
            {!!imageUrl && <Image w="50px" h="50px" objectFit={"cover"} borderRadius="full" src={imageUrl} mb={2} />}
            {!imageUrl && isPortrait && <Box w="50px" h="50px" mb={2} />}
            <Text m={0} p={0} textAlign="center" fontSize="sm">
                {props.children}
            </Text>
        </Flex>
    </>
);

export const ExpressCategoryMenuNavigation: React.FC<Props> = ({ categoryNavigationItems = [], onSelectCategory }) => {
    const [isBlurOverlayEndVisible, setIsBlurOverlayEndVisible] = useState<boolean>(false);
    const [isBlurOverlayStartVisible, setIsBlurOverlayStartVisible] = useState<boolean>(false);

    const { containerRef: wrapperRef, resetScroll } = useScrollRef();
    const navItemRefs = useRef<HTMLElement[]>([]);

    const {
        colors: { expressPrimary, expressSecondaryDark, expressTextColor, expressLightGrey },
        orientation: { isPortrait }
    } = useTheme();
    const { translate } = useLanguage();

    const textColour = getTextColor(expressPrimary);
    const secondaryTextColour = getTextColor(expressSecondaryDark);

    const onHandleScroll = () => {
        const container = wrapperRef.current;
        if (!container) return;

        const { scrollWidth, scrollHeight, clientWidth, clientHeight, scrollLeft, scrollTop } = container;

        const isOverflowing = isPortrait ? scrollWidth > clientWidth : scrollHeight > clientHeight;
        const scrollPosition = isPortrait ? scrollLeft - 10 : scrollTop - 10;
        const maxScrollPosition = isPortrait ? scrollWidth - clientWidth : scrollHeight - clientHeight;

        setIsBlurOverlayEndVisible(isOverflowing && scrollPosition + 120 < maxScrollPosition);

        const overlayNavigationVisible = isPortrait ? scrollLeft > 50 : scrollTop > 50;
        setIsBlurOverlayStartVisible(overlayNavigationVisible);
    };

    const onScrollClick = (scrollDirection: SCROLL_DIRECTION) => {
        if (!wrapperRef.current) return;
        const container = wrapperRef.current as HTMLElement;
        const { UP, DOWN, LEFT, RIGHT } = SCROLL_DIRECTION;
        const { scrollLeft, scrollTop } = container;
        const scrollByValue = 240;
        switch (scrollDirection) {
            case DOWN: {
                container.scrollTo({
                    top: scrollTop + scrollByValue,
                    behavior: "smooth"
                });
                break;
            }
            case UP: {
                container.scrollTo({
                    top: scrollTop - scrollByValue,
                    behavior: "smooth"
                });
                break;
            }
            case RIGHT: {
                container.scrollTo({
                    left: scrollLeft + scrollByValue,
                    behavior: "smooth"
                });
                break;
            }
            case LEFT: {
                container.scrollTo({
                    left: scrollLeft - scrollByValue,
                    behavior: "smooth"
                });
                break;
            }
        }
    };

    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.addEventListener("scroll", onHandleScroll);
            return () => wrapperRef?.current?.removeEventListener("scroll", onHandleScroll);
        }
    }, []);

    useEffect(() => {
        categoryNavigationItems?.forEach((item, index) => {
            if (item.isSelected && navItemRefs.current[index] && !!item.isExternalClick) {
                const navItem = navItemRefs.current[index];
                if (!isPortrait) {
                    const container = wrapperRef.current;
                    if (container) {
                        const elementTop = navItem.offsetTop;
                        const elementHeight = navItem.offsetHeight;

                        // Calculate the height of the container
                        const containerHeight = container.offsetHeight;

                        /** Scroll nav item in center view - so it is the same behaviour as horizontal */
                        const scrollPosition = elementTop - containerHeight / 2 + elementHeight / 2;

                        container.scrollTo({
                            top: scrollPosition,
                            behavior: "smooth"
                        });
                    }
                } else {
                    const container = wrapperRef.current;
                    if (container) {
                        /** Scroll into view was not working in portrait mode on chrome! */
                        const containerWidth = container.offsetWidth;
                        const elementLeft = navItem.offsetLeft;
                        const elementWidth = navItem.offsetWidth;

                        /** Scroll nav item in center view - so it is same behaviour as vertical */
                        const scrollPosition = elementLeft - containerWidth / 2 + elementWidth / 2;

                        container.scrollTo({
                            left: scrollPosition,
                            behavior: "smooth"
                        });
                    }
                }
            }
        });
        onHandleScroll();
    }, [categoryNavigationItems]);

    useEffect(() => {
        resetScroll();
    }, [categoryNavigationItems?.length]);

    const portraitScrollEndOverlay: FlexProps = isBlurOverlayEndVisible
        ? { minW: "80px", maxW: "80px" }
        : { minW: "0px", maxW: "0px" };

    const landscapeScrollEndOverlay: FlexProps = isBlurOverlayEndVisible
        ? { minH: "100px", maxH: "100px" }
        : { minH: "0px", maxH: "0px" };

    const portraitScrolStartOverlay: FlexProps = isBlurOverlayStartVisible
        ? { minW: "100px", maxW: "100px" }
        : { minW: "0px", maxW: "0px" };

    const landscapeScrollStartOverlay: FlexProps = isBlurOverlayStartVisible
        ? { minH: "100px", maxH: "100px" }
        : { minH: "0px", maxH: "0px" };

    const overlayTransition = isPortrait
        ? "min-width 0.5s ease-in-out, max-width 0.5s ease-in-out, opacity 0.5s ease-in-out"
        : "min-height 0.5s ease-in-out, max-height 0.5s ease-in-out, opacity 0.5s ease-in-out";

    return (
        <NavigationWrapper
            {...(isPortrait
                ? {
                      borderBottom: "2px solid",
                      width: "100%",
                      height: "120px",
                      overflowX: "auto",
                      overflowY: "hidden"
                  }
                : {
                      width: "120px",
                      height: "100%",
                      overflowX: "hidden",
                      overflowY: "auto"
                  })}
            style={{ WebkitOverflowScrolling: "touch" }}
            direction={isPortrait ? "row" : "column"}
            position="relative"
            gridArea={"navigation"}
            zIndex={3}
            ref={wrapperRef}
        >
            <NavigationItem
                position="sticky"
                top={0}
                left={0}
                zIndex={3}
                backgroundColor={expressPrimary}
                onClick={() => {
                    onSelectCategory();
                    resetScroll();
                }}
            >
                <Home color={textColour}>{translate("start")}</Home>
            </NavigationItem>
            <BlurredOverlay
                {...(isPortrait ? { left: 80, top: 0 } : { top: 120 })}
                {...(isPortrait ? portraitScrolStartOverlay : landscapeScrollStartOverlay)}
                style={{
                    position: "sticky",
                    zIndex: 50,
                    backdropFilter: "blur(4px)",
                    opacity: isBlurOverlayStartVisible ? 1 : 0,
                    transition: overlayTransition,
                    pointerEvents: isBlurOverlayStartVisible ? "auto" : "none"
                }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                onClick={() => onScrollClick(isPortrait ? SCROLL_DIRECTION.LEFT : SCROLL_DIRECTION.UP)}
            >
                <Box
                    mb={isPortrait ? 0 : 10}
                    ml={isPortrait ? 4 : 0}
                    color={expressPrimary}
                    fontSize={"3xl"}
                    as={isPortrait ? FaChevronLeft : FaChevronUp}
                />
            </BlurredOverlay>
            {categoryNavigationItems.map((value, index) => {
                const { categoryId, categoryName, categoryImageUrl, isSelected } = value;
                const isLastCategory = categoryNavigationItems.length - 1 === index;
                return (
                    <React.Fragment key={categoryId}>
                        <NavigationItem
                            ref={(el: HTMLElement) => (navItemRefs.current[index] = el)}
                            backgroundColor={isSelected ? expressSecondaryDark : "white"}
                            style={{ borderRight: "1px solid", borderColor: expressLightGrey }}
                            mb={isLastCategory ? 8 : 0}
                            onClick={() => onSelectCategory(value)}
                        >
                            <Category
                                imageUrl={categoryImageUrl}
                                color={isSelected ? secondaryTextColour : expressTextColor}
                                isPortrait={isPortrait}
                            >
                                {categoryName}
                            </Category>
                        </NavigationItem>
                    </React.Fragment>
                );
            })}
            <BlurredOverlay
                {...(isPortrait ? { right: 0, top: 0 } : { bottom: 0 })}
                {...(isPortrait ? portraitScrollEndOverlay : landscapeScrollEndOverlay)}
                style={{
                    backdropFilter: "blur(4px)",
                    opacity: isBlurOverlayEndVisible ? 1 : 0,
                    transition: overlayTransition,
                    pointerEvents: isBlurOverlayEndVisible ? "auto" : "none"
                }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                onClick={() => onScrollClick(isPortrait ? SCROLL_DIRECTION.RIGHT : SCROLL_DIRECTION.DOWN)}
            >
                <Box
                    mt={isPortrait ? 0 : 4}
                    color={expressPrimary}
                    fontSize={"3xl"}
                    as={isPortrait ? FaChevronRight : FaChevronDown}
                />
            </BlurredOverlay>
        </NavigationWrapper>
    );
};
